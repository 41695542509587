import { ChangeEvent, useState } from 'react';
import { useParams } from 'react-router';

import { Close, DateRange } from '@mui/icons-material';
import {
  Box,
  Chip,
  Drawer,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import AutocompleteComponent from '@/components/autocomplete';
import Button from '@/components/button';
import Card from '@/components/card';
import Checkbox from '@/components/checkbox';
import FILTERS_TO_RENDER from '@/constants/dashboardFilters';
import { useFetchEvaluation } from '@/hooks/queries/evaluations';
import { ParamsInTheUrl, URLParams } from '@/models/misc';
import LocalStorageService from '@/services/LocalStorageService';
import { formatDate } from '@/utils/datesAndTime';

interface FilterDrawerProps {
  isDrawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
  urlParams: {
    params: URLParams;
    updateParams: (newParams: Partial<URLParams>) => void;
    paramsInTheUrl: ParamsInTheUrl;
  };
  defaultFiltersParams: {
    adType: string | undefined;
    compliance: string | undefined;
    platform: string | undefined;
    createTime: string | undefined;
    keywords: string | undefined;
  };
  // keywordOptions: Array<{
  //   value: number;
  //   keyword: string;
  // }>;
}

interface Filters {
  dates: (Date | null)[];
  type: string[];
  compliance: string[];
  platform: string[];
  keywords: Array<{ keyword: string }>;
}

const parseUrlParamsToFilters = (
  urlParams: FilterDrawerProps['urlParams']
): Filters => {
  const type = urlParams.params.adType
    ? urlParams.params.adType.split(',')
    : [];

  const compliance = urlParams.params.compliance
    ? urlParams.params.compliance.split(',')
    : [];
  const platform = urlParams.params.platform
    ? urlParams.params.platform.split(',')
    : [];
  const keywords = urlParams.params.keywords
    ? urlParams.params.keywords
        .split(',')
        .map((keyword: string) => ({ keyword }))
    : [];
  const dates = urlParams.params.createTime
    ? urlParams.params.createTime
        .split(',')
        .map((date: string) => new Date(date))
    : [null, null];

  return {
    dates,
    type,
    compliance,
    platform,
    keywords
  };
};

const defaultFilters = {
  dates: [null, null],
  type: [],
  compliance: [],
  platform: [],
  keywords: []
};

const FilterDrawer = ({
  isDrawerOpen,
  setDrawerOpen,
  urlParams,
  defaultFiltersParams
}: // keywordOptions
FilterDrawerProps) => {
  const evaluationId = Number(useParams().evaluationId);
  const [filters, setFilters] = useState<Filters>(
    parseUrlParamsToFilters(urlParams)
  );

  const { data: evaluation } = useFetchEvaluation({
    id: evaluationId
  });

  const closeDrawer = () => setDrawerOpen(false);

  const handleClearFilter = () => {
    setFilters(defaultFilters);
    urlParams.updateParams(defaultFiltersParams);
    closeDrawer();
  };

  const handleConfirmFilter = () => {
    // Construct each filter string based on current selections
    const adType = filters.type.join(',');
    const compliance = filters.compliance.join(',');
    const platform = filters.platform.join(',');
    const keywords = filters.keywords
      .map((keyword) => keyword.keyword)
      .join(',');

    // Only format dates if both are not null
    let createTime = '';
    const [startDate, endDate] = filters.dates;
    if (startDate && endDate) {
      createTime = `${formatDate(startDate, 'yyyy-MM-dd')},${formatDate(
        endDate,
        'yyyy-MM-dd'
      )}`;
    }
    // Update URL params with only the non-empty parameters
    urlParams.updateParams({
      adType,
      compliance,
      platform,
      createTime,
      keywords
    });

    // Close the drawer after confirming
    closeDrawer();
  };

  const handleTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    let newTypeFilters: string[];

    if (name === 'all') {
      newTypeFilters = checked
        ? FILTERS_TO_RENDER.type.map((type) => type.name)
        : [];
    } else {
      newTypeFilters = checked
        ? [...filters.type, name]
        : filters.type.filter((type) => type !== name);
    }

    setFilters({
      ...filters,
      type: newTypeFilters
    });
  };

  const handleComplianceChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    let newTypeFilters: string[];

    if (name === 'all') {
      newTypeFilters = checked
        ? FILTERS_TO_RENDER.compliance.map((compliance) => compliance.name)
        : [];
    } else {
      newTypeFilters = checked
        ? [...filters.compliance, name]
        : filters.compliance.filter((compliance) => compliance !== name);
    }

    setFilters({
      ...filters,
      compliance: newTypeFilters
    });
  };

  const handlePlatformChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    let newPlatformFilters = [...filters.platform];

    if (name === 'all') {
      // Select all or none for all platforms
      newPlatformFilters = checked
        ? [
            ...FILTERS_TO_RENDER.platform.youtube.map(
              (platform) => platform.name
            ),
            ...FILTERS_TO_RENDER.platform.instagram.map(
              (platform) => platform.name
            ),
            ...FILTERS_TO_RENDER.platform.tiktok.map(
              (platform) => platform.name
            )
          ]
        : [];
    } else if (name === 'youtube') {
      // Toggle all YouTube sub-options
      newPlatformFilters = checked
        ? [
            ...newPlatformFilters,
            ...FILTERS_TO_RENDER.platform.youtube.map(
              (platform) => platform.name
            )
          ]
        : newPlatformFilters.filter(
            (platform) =>
              !FILTERS_TO_RENDER.platform.youtube.some(
                (ytPlatform) => ytPlatform.name === platform
              )
          );
    } else if (name === 'instagram') {
      // Toggle all Instagram sub-options
      newPlatformFilters = checked
        ? [
            ...newPlatformFilters,
            ...FILTERS_TO_RENDER.platform.instagram.map(
              (platform) => platform.name
            )
          ]
        : newPlatformFilters.filter(
            (platform) =>
              !FILTERS_TO_RENDER.platform.instagram.some(
                (igPlatform) => igPlatform.name === platform
              )
          );
    } else if (name === 'tiktok') {
      // Toggle all TikTok sub-options
      newPlatformFilters = checked
        ? [
            ...newPlatformFilters,
            ...FILTERS_TO_RENDER.platform.tiktok.map(
              (platform) => platform.name
            )
          ]
        : newPlatformFilters.filter(
            (platform) =>
              !FILTERS_TO_RENDER.platform.tiktok.some(
                (ttPlatform) => ttPlatform.name === platform
              )
          );
    } else {
      // Toggle individual platform item
      newPlatformFilters = checked
        ? [...newPlatformFilters, name]
        : newPlatformFilters.filter((platform) => platform !== name);
    }

    setFilters({
      ...filters,
      platform: newPlatformFilters
    });
  };

  const isAllPlatformsSelected =
    filters.platform.length ===
    [
      ...FILTERS_TO_RENDER.platform.youtube,
      ...FILTERS_TO_RENDER.platform.instagram,
      ...FILTERS_TO_RENDER.platform.tiktok
    ].length;

  const isAnyPlatformSelected =
    filters.platform.length > 0 && !isAllPlatformsSelected;

  const isYoutubeChecked = FILTERS_TO_RENDER.platform.youtube.every(
    (platform) => filters.platform.includes(platform.name)
  );
  const isYoutubeIndeterminate =
    !isYoutubeChecked &&
    FILTERS_TO_RENDER.platform.youtube.some((platform) =>
      filters.platform.includes(platform.name)
    );

  const isInstagramChecked = FILTERS_TO_RENDER.platform.instagram.every(
    (platform) => filters.platform.includes(platform.name)
  );
  const isInstagramIndeterminate =
    !isInstagramChecked &&
    FILTERS_TO_RENDER.platform.instagram.some((platform) =>
      filters.platform.includes(platform.name)
    );

  const isTikTokChecked = FILTERS_TO_RENDER.platform.tiktok.every((platform) =>
    filters.platform.includes(platform.name)
  );

  // Utility constants for "By Type" checkboxes
  const isAllTypesChecked =
    filters.type.length === FILTERS_TO_RENDER.type.length;
  const isSomeTypesChecked =
    filters.type.length > 0 &&
    filters.type.length < FILTERS_TO_RENDER.type.length;

  // Utility constants for "By Compliance" checkboxes
  const isAllComplianceChecked =
    filters.compliance.length === FILTERS_TO_RENDER.compliance.length;
  const isSomeComplianceChecked =
    filters.compliance.length > 0 &&
    filters.compliance.length < FILTERS_TO_RENDER.compliance.length;

  return (
    <Drawer anchor="right" open={isDrawerOpen} onClose={closeDrawer}>
      {/* Header */}
      <Paper
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: '20px',
          py: '15px',
          borderBottom: '1px solid var(--Border-Medium, rgba(0, 0, 0, 0.15))'
        }}
        elevation={0}
      >
        <Typography variant="h5" fontWeight={550}>
          Filters
        </Typography>
        <IconButton size="large" onClick={closeDrawer}>
          <Close />
        </IconButton>
      </Paper>
      {/* Body */}

      <Box
        sx={{
          overflowY: 'auto',
          height: 'calc(100vh - 150px)' // Adjust for header/footer heights
        }}
      >
        <Stack gap="20px" padding="20px">
          <Card
            title="By Keywords"
            content={
              <AutocompleteComponent
                multiple
                clearOnBlur
                value={filters.keywords}
                // options={keywordOptions}
                options={
                  LocalStorageService.getItem('keywords-to-filter')
                    .keywordsDataset || []
                }
                onChange={(event, newValue) => {
                  setFilters({
                    ...filters,
                    keywords: newValue
                  });
                }}
                getOptionLabel={(option) => option.keyword}
                isOptionEqualToValue={(option, value) =>
                  option.keyword === value.keyword
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      maxWidth: '500px'
                    }}
                    variant="outlined"
                    label="Keywords"
                    placeholder="Select the Keywords"
                  />
                )}
                renderTags={(tags, getTagProps) =>
                  tags.map((tag, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      color="primary"
                      key={`chip__${tag.keyword}`}
                      label={tag.keyword}
                      size="small"
                      variant="filled"
                    />
                  ))
                }
                renderOption={(props: any, option, { selected }) => (
                  <Stack direction="row" alignItems="center" {...props}>
                    <Checkbox checked={selected} color="primary" />
                    <Typography>{option.keyword}</Typography>
                  </Stack>
                )}
              />
            }
          />
          <Card
            title="By Date"
            content={
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                gap={1.5}
                flexGrow={1}
              >
                <Typography color="textSecondary" noWrap sx={{ width: 110 }}>
                  Date range:
                </Typography>
                <DateRange color="action" />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateRangePicker
                    calendars={1}
                    value={filters.dates as [Date | null, Date | null]}
                    onChange={(newValue) =>
                      setFilters({
                        ...filters,
                        dates: newValue
                      })
                    }
                    slots={{
                      textField: (props) => (
                        <TextField
                          size="small"
                          style={{ width: 150 }}
                          {...props}
                        />
                      )
                    }}
                    format="dd/MM/yyyy"
                    minDate={
                      new Date(evaluation?.parameters?.startCreationDate)
                    }
                    maxDate={new Date(evaluation?.parameters?.endCreationDate)}
                  />
                </LocalizationProvider>
              </Stack>
            }
          />
          <Card
            title="By Platform"
            content={
              <Stack>
                <Checkbox
                  label="All"
                  checked={isAllPlatformsSelected}
                  indeterminate={isAnyPlatformSelected}
                  onChange={handlePlatformChange}
                  name="all"
                />
                <Checkbox
                  label="Youtube"
                  checked={isYoutubeChecked}
                  indeterminate={isYoutubeIndeterminate}
                  onChange={handlePlatformChange}
                  name="youtube"
                />
                <Stack ml={3}>
                  {FILTERS_TO_RENDER.platform.youtube.map((platform) => (
                    <Checkbox
                      key={platform.name}
                      name={platform.name}
                      label={platform.label}
                      checked={filters.platform.includes(platform.name)}
                      onChange={handlePlatformChange}
                    />
                  ))}
                </Stack>
                <Checkbox
                  label="Instagram"
                  checked={isInstagramChecked}
                  indeterminate={isInstagramIndeterminate}
                  onChange={handlePlatformChange}
                  name="instagram"
                />
                <Stack ml={3}>
                  {FILTERS_TO_RENDER.platform.instagram.map((platform) => (
                    <Checkbox
                      key={platform.name}
                      name={platform.name}
                      label={platform.label}
                      checked={filters.platform.includes(platform.name)}
                      onChange={handlePlatformChange}
                    />
                  ))}
                </Stack>
                {FILTERS_TO_RENDER.platform.tiktok.map((platform) => (
                  <Checkbox
                    key={platform.name}
                    name={platform.name}
                    label={platform.label}
                    checked={isTikTokChecked}
                    onChange={handlePlatformChange}
                  />
                ))}
              </Stack>
            }
          />

          <Card
            title="By Type"
            content={
              <Stack>
                <Checkbox
                  name="all"
                  checked={isAllTypesChecked}
                  indeterminate={isSomeTypesChecked}
                  label="All"
                  onChange={handleTypeChange}
                />
                {FILTERS_TO_RENDER.type.map((type) => (
                  <Checkbox
                    key={type.name}
                    name={type.name}
                    label={type.label}
                    onChange={handleTypeChange}
                    checked={filters.type.includes(type.name)}
                  />
                ))}
              </Stack>
            }
          />

          <Card
            title="By Compliance"
            content={
              <Stack>
                <Checkbox
                  name="all"
                  checked={isAllComplianceChecked}
                  indeterminate={isSomeComplianceChecked}
                  label="All"
                  onChange={handleComplianceChange}
                />
                {FILTERS_TO_RENDER.compliance.map((compliance) => (
                  <Checkbox
                    key={compliance.name}
                    name={compliance.name}
                    label={compliance.label}
                    onChange={handleComplianceChange}
                    checked={filters.compliance.includes(compliance.name)}
                  />
                ))}
              </Stack>
            }
          />
        </Stack>
      </Box>
      {/* Footer */}
      <Paper
        sx={{
          gap: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: '20px',
          py: '20px',
          borderTop: '1px solid var(--Border-Medium, rgba(0, 0, 0, 0.15))',
          position: 'absolute',
          bottom: 0,
          width: '100%'
        }}
        elevation={0}
      >
        <Button text="clear" variant="outlined" onClick={handleClearFilter} />
        <Button text="confirm" onClick={handleConfirmFilter} />
      </Paper>
    </Drawer>
  );
};

export default FilterDrawer;
