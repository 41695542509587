import { GridRowId } from '@mui/x-data-grid';

import { decomposeParams } from '@/api';
import { URLParams } from '@/models/misc';
import { Posts } from '@/models/posts';

type EntityId = number | GridRowId;

export const getRoute = {
  evaluations: {
    LIST: (params?: URLParams) => `/evaluations${decomposeParams(params)}`,
    DETAIL: (evaluationId: EntityId, params?: URLParams) =>
      `/evaluations/${evaluationId}${decomposeParams(params)}`,
    POST: (
      evaluationId: EntityId,
      postId: EntityId,
      params?: Posts.DetailParams
    ) =>
      `/evaluations/${evaluationId}/post/${postId}${decomposeParams(params)}`,
    STATISTICS: (evaluationId: EntityId, params?: URLParams) =>
      `/evaluations/${evaluationId}/statistics${decomposeParams(params)}`
  },
  creators: {
    LIST: (params?: URLParams) => `/creators${decomposeParams(params)}`,
    DETAIL: (creatorId: EntityId, params?: URLParams) =>
      `/creators/${creatorId}${decomposeParams(params)}`
  },
  creatorGroups: {
    LIST: (params?: URLParams) => `/creator-groups${decomposeParams(params)}`,
    DETAIL: (groupId: EntityId, params?: URLParams) =>
      `/creator-groups/${groupId}${decomposeParams(params)}`
  },
  keywords: {
    LIST: (params?: URLParams) => `/keyword-groups${decomposeParams(params)}`,
    DETAIL: (keywordsGroupId: EntityId, params?: URLParams) =>
      `/keyword-groups/${keywordsGroupId}${decomposeParams(params)}`
  },
  dashboard: {
    WOKRSPACE: (params?: URLParams) => `/dashboard${decomposeParams(params)}`
  },
  auth: {
    LOGIN: () => '/auth/login',
    LOGOUT: () => `/auth/logout`
  },
  RELEASE_NOTES: () => '/release-notes'
};

export default getRoute;
