import { AxiosPromise } from 'axios';

import { Workspaces } from '@/models/workspaces';

import { api, decomposeParams } from '.';

export const getWorkspaceStatistics = (
  params: any
): AxiosPromise<Workspaces.Statistics> =>
  api.get(`v2/workspaces/statistics${decomposeParams(params)}`);
