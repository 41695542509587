import { Fragment, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  ListAlt,
  PieChart,
  SwitchAccount,
  Campaign,
  Language,
  AccountCircle,
  SignalWifiStatusbar4Bar,
  ImageSearch
} from '@mui/icons-material';
import {
  Badge,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Stack,
  Tooltip
} from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import InfluencerMonitorIcon from '@/components/icons/InfluencerMonitorIcon';
import InfoTooltip from '@/components/infoTooltip';
import { useGetScraperStatus } from '@/hooks/queries/scraper';
import { useAppSelector } from '@/hooks/redux';
import useMenuAnchor from '@/hooks/useMenuAnchor';
import usePrismic from '@/hooks/usePrismic';
import useWorkspaceNavigate from '@/hooks/useWorkspaceNavigate';
import getRoute from '@/utils/getRoute';
import { statusChecker } from '@/utils/scraperStatus';

import AccountInfoMenu from './_partials/AccountInfoMenu';
import ScraperStatusDialog from './_partials/ScraperStatusDialog';
import WorkspaceMenu from './_partials/WorkspaceMenu';

type Item = {
  href?: Array<string>;
  checkNotActive?: Array<string>;
  icon?: React.ElementType;
  title?: string;
  onBoardingTitle: string;
  onBoardingText: string;
};

const items: Item[] = [
  {
    href: [getRoute.dashboard.WOKRSPACE()],
    icon: PieChart,
    title: 'Dashboard',
    onBoardingTitle: 'Workspace dashboard',
    onBoardingText: 'Get an overview of your workspace evaluations.'
  },
  {
    href: [getRoute.evaluations.LIST()],
    icon: ImageSearch,
    title: 'Evaluations',
    onBoardingTitle: 'Manage evaluations',
    onBoardingText: 'Create your own evaluation.'
  },
  {
    href: [getRoute.creators.LIST(), getRoute.creatorGroups.LIST()],
    icon: SwitchAccount,
    title: 'Creators',
    onBoardingTitle: 'Manage creators',
    onBoardingText: 'Add and edit creators.\nCreate groups of creators.'
  },
  {
    href: [getRoute.keywords.LIST()],
    icon: ListAlt,
    title: 'Keyword Groups',
    onBoardingTitle: 'Manage keyword groups',
    onBoardingText: 'Create and edit keyword groups.'
  }
];

const releaseNotesItem = {
  href: [getRoute.RELEASE_NOTES()],
  icon: Campaign,
  title: 'Release Notes',
  onBoardingTitle: 'See release notes',
  onBoardingText: 'Stay up to date with the latest release notes.'
};

const useStyles = makeStyles()((theme) => ({
  desktopDrawer: {
    width: '64px',
    height: '100vh',
    // top: '64px',
    borderRadius: '0px',
    backgroundColor: theme.palette.primary.main
  },
  selected: {
    backgroundColor: `${theme.palette.primary.contrastText}14`
  },
  selectedIcon: {
    color: theme.palette.primary.contrastText
  },
  icon: {
    color: `${theme.palette.primary.contrastText}8a`
  },
  listItemButton: {
    width: '63px',
    minHeight: '48px',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const NavBar = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const workspaceNavigate = useWorkspaceNavigate();
  const location = useLocation();
  const { totalNewReleases } = usePrismic();
  const [isStatusDialogOpen, setIsStatusDialogOpen] = useState(false);
  const user = useAppSelector((state) => state.user.user);

  const { data: scraperStatus, isError: isScraperError } =
    useGetScraperStatus();

  const {
    anchorEl: anchorElAccount,
    handleOpen: handleAccountInfo,
    handleClose: closeAccountMenu,
    isOpen: isAccountMenuOpen
  } = useMenuAnchor();
  const {
    anchorEl: anchorElWorkspace,
    handleOpen: handleLanguageInfo,
    handleClose: closeWorkspaceMenu,
    isOpen: isWorkspaceMenuOpen
  } = useMenuAnchor();

  const isActive = (href: Array<string>) =>
    href.some((item) => location.pathname.includes(item));

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.desktopDrawer }}
      open
      variant="persistent"
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        paddingY="9px"
        sx={{ cursor: 'pointer' }}
        onClick={() => navigate('/')}
      >
        <InfluencerMonitorIcon fontSize="large" />
      </Stack>
      <Stack justifyContent="space-between" height="100%">
        <List>
          {items.map((item) => (
            <Fragment key={`navbar_${item.title}`}>
              <Tooltip
                enterDelay={1500}
                arrow
                placement="right"
                title={
                  <InfoTooltip
                    icon
                    title={item.onBoardingTitle}
                    text={item.onBoardingText}
                  />
                }
              >
                {item.href && item.icon ? (
                  <ListItem
                    className={isActive(item.href) ? classes.selected : ''}
                    disablePadding
                  >
                    <ListItemButton
                      className={classes.listItemButton}
                      onClick={() => {
                        if (item.href) {
                          workspaceNavigate(item.href[0]);
                        }
                      }}
                      selected={isActive(item.href)}
                    >
                      <ListItemIcon
                        style={{
                          justifyContent: 'center'
                        }}
                      >
                        <item.icon
                          className={
                            isActive(item.href)
                              ? classes.selectedIcon
                              : classes.icon
                          }
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                ) : (
                  <></>
                )}
              </Tooltip>
              <Divider />
            </Fragment>
          ))}
        </List>
        <List>
          <Tooltip
            arrow
            placement="right"
            enterDelay={1500}
            title={
              <InfoTooltip
                icon
                title="Release notes"
                text="Keep track of the latest release notes."
              />
            }
          >
            <ListItem
              className={isActive(releaseNotesItem.href) ? classes.selected : ''}
              disablePadding
            >
              <ListItemButton
                className={classes.listItemButton}
                onClick={() => {
                  workspaceNavigate(releaseNotesItem.href[0]);
                }}
                selected={isActive(releaseNotesItem.href)}
              >
                <ListItemIcon
                  style={{
                    justifyContent: 'center'
                  }}
                >
                  <Badge
                    badgeContent={totalNewReleases}
                    color="error"
                    invisible={totalNewReleases === 0}
                  >
                    <Campaign
                      className={
                        isActive(releaseNotesItem.href)
                          ? classes.selectedIcon
                          : classes.icon
                      }
                    />
                  </Badge>
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          </Tooltip>
          <Divider />
          <ListItem disablePadding>
            <ListItemButton
              className={classes.listItemButton}
              onClick={() => setIsStatusDialogOpen(true)}
            >
              <ListItemIcon
                style={{
                  justifyContent: 'center'
                }}
              >
                {!isScraperError && (
                  <Badge
                    color={statusChecker(scraperStatus).severity}
                    variant="dot"
                  >
                    <SignalWifiStatusbar4Bar className={classes.icon} />
                  </Badge>
                )}
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          {user.role === 'admin' && (
            <>
              <Divider />
              <ListItem disablePadding>
                <ListItemButton
                  className={classes.listItemButton}
                  onClick={handleLanguageInfo}
                >
                  <ListItemIcon
                    style={{
                      justifyContent: 'center'
                    }}
                  >
                    <Language className={classes.icon} />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            </>
          )}
          <Divider />

          <Tooltip arrow placement="right" title="Log out">
            <ListItem disablePadding>
              <ListItemButton
                className={classes.listItemButton}
                onClick={handleAccountInfo}
              >
                <ListItemIcon
                  style={{
                    justifyContent: 'center'
                  }}
                >
                  <AccountCircle className={classes.icon} />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          </Tooltip>
        </List>
      </Stack>
      {isAccountMenuOpen && (
        <AccountInfoMenu
          isOpen={isAccountMenuOpen}
          anchorEl={anchorElAccount}
          handleClose={closeAccountMenu}
        />
      )}

      {/* If added the flag, the menu opens with a glitch */}
      <WorkspaceMenu
        isOpen={isWorkspaceMenuOpen}
        anchorEl={anchorElWorkspace}
        handleClose={closeWorkspaceMenu}
      />

      {isStatusDialogOpen && (
        <ScraperStatusDialog
          isOpen={isStatusDialogOpen}
          onClose={() => setIsStatusDialogOpen(false)}
        />
      )}
    </Drawer>
  );
};

export default NavBar;
